/* PrivacyPolicy.css */
body {
  margin: 0;
  font-family: "Arial", sans-serif;
  background-color: #eaeaea; /* Light background for contrast */
}

.privacy-policy {
  max-width: 80%; /* Maximum width for the container */
  width: 90%; /* Responsive width, taking 90% of the display */
  padding: 40px; /* Padding to avoid content touching edges */
  font-family: "Arial", sans-serif;
  background-color: #ffffff; /* White background for the policy */
  border-radius: 8px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
  margin: 20px auto; /* Center the container with auto margins */
  box-sizing: border-box; /* Include padding in width calculation */
}

.privacy-policy h1 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
  border-bottom: 2px solid #007bff; /* Underline for the main title */
  padding-bottom: 10px;
}

.privacy-policy h2 {
  font-size: 2em; /* Slightly larger for subheadings */
  color: #555;
  margin-top: 30px;
  margin-bottom: 10px;
}

.privacy-policy p,
.privacy-policy ul {
  font-size: 1.1em;
  color: #666;
  line-height: 1.8; /* Increased line height for readability */
}

.privacy-policy ul {
  list-style-type: disc;
  padding-left: 20px;
}

.privacy-policy strong {
  color: #333;
}

.privacy-policy a {
  color: #007bff;
  text-decoration: none;
}

.privacy-policy a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .privacy-policy {
    padding: 20px; /* Reduced padding for smaller screens */
  }

  .privacy-policy h1 {
    font-size: 2em; /* Smaller title on mobile */
  }

  .privacy-policy h2 {
    font-size: 1.5em; /* Smaller subheadings on mobile */
  }
}
